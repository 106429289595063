/* You can add global styles to this file, and also import other style files */

html, body { height: 100% !important; }
body { margin: 0; font-family: Prompt, "Helvetica Neue", sans-serif; }

.success.mat-snack-bar-container {
  background-color: rgba(132,189,0, .93); // #84BD00;
  color:white;
}

.error.mat-snack-bar-container {
  background-color: rgba(210,38,48, .93); //#D22630
  color:white;
}

.warn.mat-snack-bar-container {
  background-color: rgba(232,119,34, .93); //#E87722
  color:white;
}

.info.mat-snack-bar-container {
  background-color: rgba(0,181,226, .93); //#00B5E2
  color:white;
}

.logo {
  position: relative;
  left: 0px;
  background-color: white;
  border: solid;
  border-color: gainsboro;
  border-width: 0 0 .1px 0;
}

.tiny-space {
  padding-left: 5px;
  padding-right: 5px;
}

.layout-three-column {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

.layout-three-column > div {
  flex: 1 0 33%;
}

.layout-full-right {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.layout-full-left {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
}

table {
  margin: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.mat-progress-spinner {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  height: 100%;
  opacity: .5;
  z-index: 999;
}

.overlay {
  position: fixed;
	width: 100%;
	height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 9999
}

.mat-success {
  background-color: rgba(132,189,0, .93) !important;
  color: #fff !important;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.error-validate {
  color: red;
}

//TABLE Custom styles

th.mat-header-cell {
  text-align: center !important;
  padding: 0.1rem 0.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-size: small;
  color: black;
  text-decoration: underline;
}
td.mat-cell {
  text-align: left;
  padding: 0.1rem 0.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
td.numberFormat {
  text-align: right;
}
td.rowNumber {
  text-align: center;
  width: 40px;
}
.routerLinkActive {
  background-color: rgba(0, 0, 0, 0.2);
}