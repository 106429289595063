/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';

@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600');

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat-core();



$mdc-typography-font-family: unquote("Prompt, sans-serif");

// Define the default theme (same as the example above).
$candy-app-primary: mat-palette($mat-blue, 900);
$candy-app-accent:  mat-palette($mat-grey, 400);
$candy-app-warn: mat-palette($mat-red);

$candy-app-theme:   mat-light-theme(
    $candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include the default theme styles (color and default density)
@include angular-material-theme($candy-app-theme);
